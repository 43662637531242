import axios from "axios";
import { useEffect, useState, useCallback } from "react";

import { Link } from "react-router-dom";
import Sidebar from "../../components/sidebar/Sidebar";
import OneCate from "./oneCate";
import Loader from "../../components/UI/loading/loading";

const Company = () => {
    const [update, setupdate] = useState();
    const [cate, setCate] = useState();
    const [companies, setCompanies] = useState();
    const [products, setProducts] = useState([<p>empty...</p>]);
    const [selectedCompany, setSelected] = useState(null);
    const [flag, setflag] = useState(false);
    const [loading, setLoading] = useState(false);
    const [backShow,setBack]=useState(false);


function backHandler(){
setBack(false)
setflag(false)
}

    const onClickHandler = () => {


        console.log('hi')
        axios.get(
            `https://cars-project-83013-default-rtdb.firebaseio.com/company/${cate}.json`
        )
            .then(
                res => {
                    let temp = [];
                    setLoading(false)
                    console.log(res.data)
                    if (
                        res.data) {
                        Object.keys(res.data).forEach((key) => (temp.push(<OneCate cate={cate} companyName={'asd'} deletebale keyof={key} click={setSelected} key={key} data={res.data[key]} />)));

                    }
                    else temp = [<p>empty...</p>]
                    setProducts(temp);
                   
                }
            )

    }
    useEffect(() => {


        onClickHandler();
    }, [cate])


    let temp = [
        <div className="product" onClick={() => {setCate('Petrol');  setflag(true); setLoading(true); setBack(true)}} style={{ height: '200px', justifyContent: 'center', alignItems: 'center', display: 'flex', fontSize: '30px', fontWeight: '600' }}>
            Petrol
        </div>,
        <div className="product"
            onClick={() =>{ setCate('Coffe');  setflag(true); setLoading(true); setBack(true)}} style={{ height: '200px', justifyContent: 'center', alignItems: 'center', display: 'flex', fontSize: '30px', fontWeight: '600' }}>
            coffee
        </div>

    ]



    return (
        <div className="home">

            <Sidebar />
            <div className="container">

                <Link to='/addCompany'>
                    <div className="addBtn ms-4">
                        add new Company
                    </div></Link>
{backShow && <span onClick={backHandler} className="back-btn"> back</span>}
                <div className="product-contianer" style={{justifyContent:'center'}}>
                    { !loading  ? (flag ? <>
                                   {products}</> : temp): <div style={{width:'200px',margin:'auto',marginTop:'100px'}}> <Loader/></div>}

                </div>


            </div>
        </div>
    );
}

export default Company;