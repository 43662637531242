import DeleteIcon from '@mui/icons-material/Delete';

import axios from 'axios';
import { useState } from 'react';

import Loader from '../../components/UI/loading/loading';
const OneCate = ({ keyof, data, click, deletebale, companyName }) => {
  const [loading, setloading] = useState(false);


  function OnDeleteHandler() {
    setloading(true)
    axios.delete(`https://cars-project-83013-default-rtdb.firebaseio.com/Products/${companyName}/${keyof}.json`).then((res) => {
   
      window.location.reload(true);
    })
  }
  return (

    <div className="product" onClick={() => (click(data.name))} >
      {
        !loading &&
        <>
          <img src={data.image} />
          <p className="title"> {data.name}</p>
          {
            deletebale ? <div className="buttons">
              <span onClick={OnDeleteHandler} className="delete"><DeleteIcon /> Delete</span>

            </div> : null
          }
        </>
      }
      {loading && <div className='loaderContianer'> <Loader /></div>}

    </div>

  );
}

export default OneCate;