
import './App.css';
import Login from './pages/login'
import { Route, Routes } from 'react-router-dom';
import Home from './pages/home/home'
import AddCompany from './pages/addcompany/addCompany';
import Products from './pages/addProduct/products';

import AddProduct from './pages/addProduct/addProducts';
import Company from './pages/addcompany/company';
import { useContext } from 'react';
import { AuthProvider } from './context/AuthContext';
function App() {
  const aut = useContext(AuthProvider);

  return (


    <Routes>
      <Route path='/login' element={<Login />} />
    {
      aut.currentUser? 
    <>  <Route index path='/Company' element={<Company />} />
    <Route index path='/Products' element={<Products />} />


    <Route path='/AddCompany' element={<AddCompany />} />

    <Route path='/addProduct' element={<AddProduct />} />
 </>: null
    }
       <Route path='*' element={<Login />} />
    </Routes>





  );
}

export default App;
