import { useState, useEffect } from "react";

import axios from 'axios';

import { TextField } from "@mui/material";
import Sidebar from "../../components/sidebar/Sidebar";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import Loader from "../../components/UI/loading/loading";
const AddCompany = () => {
    const [name, setName] = useState('');
   
    const [file, setfile] = useState();
    const [fileurl, setFileUrl] = useState('');
    const [Error, setError] = useState(false)
    const [options, setoptions] = useState('Petrol');
    const [Loading, setLoading] = useState(false)
   const [Done, setDone] = useState(false)
    // Add a new document in collection "cities"
    const handleSubmit = async (e) => {

        e.preventDefault();
        setLoading(true)
        function uploadFile() {
            const storage = getStorage();
            const storageRef = ref(storage, file.name);
            uploadBytes(storageRef, file).then((snapshot) => {
                getDownloadURL(snapshot.ref).then((downloadurl) => {
                    setFileUrl(downloadurl)

                    const data = {
                        name: name,
                        image: downloadurl,
                        cateogry: options
                    }

                  

                    if ( !name || !options  ) {
setLoading(false)
                        setError(true)
                        return;
                    }
                    axios.post(`https://cars-project-83013-default-rtdb.firebaseio.com/company/${options}.json`, data)
                        .then(res => {
                            console.log(res);
                            setLoading(false)
                            setDone(true);
                            setTimeout(()=>{
setDone(false)
                            },3000)
                        })
                        .catch(err => console.log(err))

                }).catch(err => console.log(err))

            })
                .catch(err => console.log(err));






        }

        file && uploadFile();

    }
    function hanelChange(e, attr) {
        attr(e);
    }
    function onChangeFile(e) {

        setfile(e.target.files[0])

    }

  
  
    return (
        <div className="home">

            <Sidebar />
            <div className="container">

                <div className="form-container">
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <TextField onChange={(e) => {
                            hanelChange(e.target.value, setName)
                        }} label={'name of company'} />

                        <input style={{display:'block',margin:'10px 0px'}} onChange={onChangeFile} type="file" accept="image/*" name="" id="" />
                        <label className="ok" htmlFor="">Cateogry</label>
                        <select name="" id="" onChange={(e)=>setoptions(e.target.value)}>
                            <option selected value="Petrol">Petrol</option>
                            <option value="Coffe">Coffe</option>
                        </select>
                        <button style={{display:'block'}}>submit</button>
                        { Loading  && <Loader />}
                    </form>
                    {Done && <div className="done"> added succesfully</div>}
                </div>
            </div>
        </div>

    );
}

export default AddCompany;
