import { useContext, useState } from 'react';
import './login.scss'
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { AuthProvider } from '../context/AuthContext';
const Login = () => {
    const [email, setEmail] = useState('');
    const [Password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const navigate=  useNavigate();
    const aut = useContext(AuthProvider);
    function onChangeHandler(value) {
        setEmail(value)
    }
    function onChangeHandlerPassword(value) {
        setPassword(value)
    }
    function onSubmitHandler(e) {
        e.preventDefault();



        signInWithEmailAndPassword(auth, email, Password)
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
               aut.dispatch({type:'LOGIN',payload:user})
                if(user)
                {
                    navigate('/company')
                }
                // ...
            })
            .catch((error) => {
setError('wrong passowrd')
                console.log(error)
                // ..
            });
        if (email === '' || Password === '') {
            setError('please fill all inputs');
        }
        else { }
    }
    return (
        <div className='login-container'>
            <div className='box'>    <h1 style={{ textAlign: 'center' }}>Login</h1>
                <form onSubmit={onSubmitHandler}>
                    <input type="text" className='input' onChange={(e) => onChangeHandler(e.target.value)} />
                    <input type="password" name="" onChange={(e) => onChangeHandlerPassword(e.target.value)} className='input' id="" />
                    {
                        error ? <p style={{ color: 'red', textAlign: 'center' }}>{error}</p> : null
                    }                    <button className='form-btn'>Submit</button>
                </form>
            </div>
        </div>
    );
}

export default Login;