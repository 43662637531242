import "./sidebar.scss";

import { Link } from "react-router-dom";
import CategoryIcon from '@mui/icons-material/Category';
import ApartmentIcon from '@mui/icons-material/Apartment';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { useContext } from "react";
import { AuthProvider } from "../../context/AuthContext";

const Sidebar = () => {
  const auth=useContext(AuthProvider);
function logout(){
auth.dispatch({type:'LOGOUT'})
}
  return (
    <div className="sidebar">
      <div className="top">
        <Link to="/" style={{ textDecoration: "none" }}>
          <span className="logo">Petrol admin</span>
        </Link>
      </div>
      <hr />
      <div className="center">
        <ul>
      
          <p className="title">LISTS</p>
          
       
          <Link to="/products" style={{ textDecoration: "none" }}>
            <li>
            <Inventory2OutlinedIcon className="icon" />
              <span>Products</span>
            </li>
          </Link>
          <Link to="/company" style={{ textDecoration: "none" }}>
            <li>
            <ApartmentIcon className="icon" />
              <span>company</span>
            </li>
          </Link>
          
        <Link to='/login'>
        <li onClick={logout}>
          <LogoutOutlinedIcon className="icon" />
          
            <span>Logout</span>
          </li></Link>
        </ul>
      </div>
      
    </div>
  );
};

export default Sidebar;
