import { useState, useEffect } from "react";

import axios from 'axios';

import { TextField } from "@mui/material";
import Sidebar from "../../components/sidebar/Sidebar";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import Loader from "../../components/UI/loading/loading";

const AddProduct = () => {
    const [name, setName] = useState('');

    const [file, setfile] = useState();
    const [fileurl, setFileUrl] = useState('');
    const [des, setDes] = useState('')
    const [companyName, setNames] = useState('');
    const [CompanyOptions, setCompanyOptions] = useState([])
    const [Type, setType] = useState('second')
    const [Error, setError] = useState(false)
    const [docment, setDocment] = useState();
    const [Loading, setLoading] = useState(false)
    const [Done, setDone] = useState(false)
    // Add a new document in collection "cities"
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        function uploadFile() {
            const storage = getStorage();

            const storageRef = ref(storage, file.name);
            uploadBytes(storageRef, file).then((snapshot) => {
                getDownloadURL(snapshot.ref).then((downloadurl) => {
                    setFileUrl(downloadurl)

                    uploadBytes(ref(storage, docment.name), docment).then((snapshot) => {
                        getDownloadURL(snapshot.ref).then((docUrl) => {
                           
        
                            const data = {
                                name: name,
                                image: downloadurl,
                                company: companyName,
                                des: des,
                                type: Type,
                                doc:docUrl
        
                            }
        
                            if (!name || !downloadurl || !companyName || !des || !Type || !docUrl) {
                                setError(true);
                                setLoading(false)
                                return
                            }
        
                            axios.post(`https://cars-project-83013-default-rtdb.firebaseio.com/Products/${companyName}.json`, data)
                                .then(res => {
                                    console.log(res)
                                    setLoading(false);
                                    setDone(true);
                                    setTimeout(() => {
                                        setDone(false)
                                    }, 3000)
                                })
                                .catch(err => console.log(err))
        
                        }).catch(err => console.log(err))
        
                    })
                        .catch(err => console.log(err));
                  

                   

                }).catch(err => console.log(err))

            })
                .catch(err => console.log(err));






        }

        file && docment && uploadFile();

    }
    function hanelChange(e, attr) {
        attr(e);
    }
    function onChangeFile(e) {

        setfile(e.target.files[0])

    }
    function onChangeDocment(e) {

        setDocment(e.target.files[0])

    }



    useEffect(() => {
        let obj;

        let anothertemp = [];
        axios.all(
            [
                axios.get(`https://cars-project-83013-default-rtdb.firebaseio.com/company/Petrol.json`)
                ,
                axios.get(`https://cars-project-83013-default-rtdb.firebaseio.com/company/Coffe.json`)
                ,

            ]
        ).then(data => {


            obj = { ...data[0].data, ...data[1].data }

            if (anothertemp) {

                for (const key in obj) {
                    anothertemp.push(obj[key].name)
                }

            }
            setNames(anothertemp[0]);
            setCompanyOptions(anothertemp)
        })



    }, [])

    return (
        <div className="home">

            <Sidebar />
            <div className="container">

                <div className="form-container">
                    <form onSubmit={!Loading?(e) => handleSubmit(e):()=>{}}>
                        <TextField onChange={(e) => {
                            hanelChange(e.target.value, setName)
                        }} label={'Name of product'} />
                        <TextField onChange={(e) => {
                            hanelChange(e.target.value, setType)
                        }} label={'Type (SubTitle)'} />
                        <p style={{ margin: 0 }}>add product picture</p>
                        <input onChange={onChangeFile} type="file" name="" id="" accept="image/*" />
                        <p style={{ margin: '10px 0px' }}>add product docment</p>
                        <input onChange={onChangeDocment} type="file" name="" id="" />

                        <div>
                            <label className="ok" htmlFor=""> Company name</label>
                            <select name="" id="" onChange={(e) => hanelChange(e.target.value, setNames)}>


                                {CompanyOptions.map((e, i) => { return <option defaultValue={!i} key={e} value={e}>{e}</option> })}
                            </select>
                        </div>
                        <label className="ok" htmlFor="">Descreption</label>
                        <textarea placeholder="Descreption of product" name="" id="" cols="30" rows="10" onChange={(e) => hanelChange(e.target.value, setDes)}></textarea>
                        {!Loading ?<button>Add Product</button> : null}
                        {Loading && <Loader />}
                    </form>
                    {Error ? <p style={{ color: 'red' }}>please fill the form</p> : null}
                    {Done && <div className="done"> added succesfully</div>}
                </div>
            </div>
        </div>

    );
}

export default AddProduct;