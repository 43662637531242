import { createContext, useEffect, useReducer } from "react";
import AuthReducer from "./AuthReduces";

const INITIAL_STATE={
    currentUser:JSON.parse(localStorage.getItem('user')) || null
}
export const AuthProvider=createContext(INITIAL_STATE);

export const AuthContextProvider=({children})=>{
    const [state,dispatch]=useReducer(AuthReducer,INITIAL_STATE);
useEffect(() => {
localStorage.setItem('user',JSON.stringify(state.currentUser))
}, [state.currentUser])

    return (
        <AuthProvider.Provider value={{currentUser:state.currentUser,dispatch}} >
            {children}
        </AuthProvider.Provider>
    )
}
