import axios from "axios";
import { useEffect, useState, useCallback } from "react";

import { Link } from "react-router-dom";
import Sidebar from "../../components/sidebar/Sidebar";
import OneCate from "./oneCate";

const Cateagory = () => {
    const [update, setupdate] = useState();
    const [cate, setCate] = useState({});
    const [companies, setCompanies] = useState();
    const [products, setProducts] = useState([<p>empty...</p>]);
    const [selectedCompany, setSelected] = useState(null);
    const [flag, setflag] = useState(true);
    const [elments, setElments] = useState([])
    const reRender = useCallback(() => setupdate({}), []);


    useEffect(() => {

        if (elments.length && selectedCompany===null) {

            setflag(true)
        }
     



    }, [flag])
 const onClickHandler=(id)=>{

    setSelected(id);

  
    axios.get(
        `https://cars-project-83013-default-rtdb.firebaseio.com/Products/${id}.json`
    )
    .then(
        res=>{
            let temp=[];
            console.log(res.data)
           if(
            res.data )
           {
            Object.keys(res.data).forEach((key) => (temp.push(<OneCate refresh={reRender} companyName={id} deletebale keyof={key} click={setSelected} key={key} data={res.data[key]} />)));
        
           }
           else temp=[<p>empty...</p>]
            setProducts(temp);
            setflag(false);
        }
    )

 }

    useEffect(() => {
        axios.all([
            axios.get(`https://cars-project-83013-default-rtdb.firebaseio.com/company/Petrol.json`),
            axios.get(`https://cars-project-83013-default-rtdb.firebaseio.com/company/Coffe.json`)
        ]).then((data1) => {
            const obj = { ...data1[0].data, ...data1[1].data }
            setCompanies(obj)
        })
    }, [])

    useEffect(() => {
        let temp = []
        if (companies) {
            Object.keys(companies).forEach((key) => (temp.push(<OneCate refresh={reRender} keyof={key} click={onClickHandler} key={key} data={companies[key]} />)));
        }
        setElments(temp);


    }, [companies])



    return (
        <div className="home">

            <Sidebar />
            <div className="container">
                
                <Link to='/addProduct'>
                <div className="addBtn ms-4">
              add new Product
                </div></Link>
                 {flag ?<h1 className="select"> select company to show products</h1>:<h1 className="select">Products</h1>}
                <div className="product-contianer">
                    {flag ? <> 
                    {elments}</> : products}
                 
                </div>


            </div>
        </div>
    );
}

export default Cateagory;